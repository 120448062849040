import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Route, Router } from 'react-router';
import App from './App';
import HeartSpinnerGlobal from './componenets/globalSpinnner';
import reportWebVitals from './reportWebVitals';
import AuthComponent from './utils/authContext';
import history from './utils/history';
import './utils/i18n';
console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV === 'test') {
  const { worker } = require('./mocks/browser');
  worker.start();
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<HeartSpinnerGlobal />}>
      <Router history={history}>
        <AuthComponent>
          <App />
        </AuthComponent>
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
