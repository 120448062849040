import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import config from './config';
import history from './history';
export interface RefreshToken {
  firstName: string;
  lastName: string;
  companyName: string;
  username: string;
  userType: 'Logistics';
  accessToken: string;
}
interface AxiosErrInterceptor extends AxiosRequestConfig {
  _retry: boolean;
}

export interface Filters {
  [key: string]: string;
}
export interface FiltersAndPagination {
  page: number;
  limit: number;
  filters?: Filters[];
}
export interface Login {
  firstName: string;
  lastName: string;
  companyName: string;
  username: string;
  userType: 'Logistics';
  accessToken: string;
  alerts?: string;
}
interface Pagination<T> {
  page: number;
  results: number;
  totalCount: number;
  data: T;
}

export interface PurchaseOrderRow {
  _id: string;
  salesOrderId: string;
  vendorId: string;
  createdAt: string;
  status: string;
  deliveredAt?: string;
  invoiceNumber?: string;
  deliveryTicketNumber?: string;
}

export interface SalesOrderRow {
  _id: string;
  buyerId: string;
  createdAt: string;
  status: string;
  isReadyForShipment: boolean;
  deliveredAt?: string;
  shippingNotes?: string;
  invoiceNumber?: string;
  deliveryTicketNumber?: string;
  numOfdeliveredPurchaseOrders: number;
  numOfPurchaseOrders: number;
}

export interface StockProduct {
  productId: string;
  variantId: string;
  quantity: number;
  variant_name: string;
  variant_arabicName: string;
  product_name: string;
  product_arabicName: string;
}

export interface Buyer {
  _id: string;
  name: string;
  phone: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  governorate: string;
  city: string;
  district: string;
  addressLine: string;
  addressLine2: string;
}
export interface Vendor {
  _id: string;
  name: string;
  phone: string;
  address: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
}

export interface item {
  productId: string;
  product: Product;
  variants: Variant[];
  totalQty?: number;
}

export interface Product {
  slug: string;
  name: string;
  arabicName: string;
  coverImage: string;
}
export interface Variant {
  productId: string;
  variantId: string;
  quantity: number;
  productVariant: {
    _id: string;
    name: string;
    arabicName: string;
    product: Product;
  };
}
export interface IncomingOrderInterface {
  _id: string;
  vendorId: string;
  createdAt: string;
  status: string;
  deliveredAt?: string;
  vendor: Vendor;
  salesOrderId: string;
  items: item[];
  total?: number;
}

export interface OutgoingOrderInterface {
  _id: string;
  buyerId: string;
  createdAt: string;
  status: string;
  isReadyForShipment: boolean;
  deliveredAt?: string;
  shippingNotes?: string;
  invoiceNumber?: string;
  deliveryTicketNumber?: string;
  buyer: Buyer;
  purchaseOrders: IncomingOrderInterface[];
  numOfdeliveredPurchaseOrders: number;
  numOfPurchaseOrders: number;
  numOfProducts?: number;
  total?: number;
}

class Axios {
  private readonly instance: AxiosInstance;
  private interceptorId: number;
  constructor() {
    this.instance = axios.create({
      baseURL: config.REACT_APP_API,
    });

    this.interceptorId = this.AddRefreshInterceptor();

    this.instance.defaults.withCredentials = true;
  }
  private createQueryObj(filters: FiltersAndPagination) {
    let obj = {};
    filters.filters?.forEach((el) => {
      obj = { ...obj, ...el };
    });
    return obj;
  }
  private setToken(token: string) {
    this.instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  private AddRefreshInterceptor() {
    const interceptor = this.instance.interceptors.response.use(
      undefined,
      this.RefreshInterceptor
    );
    return interceptor;
  }
  private EjectInterceptor() {
    this.instance.interceptors.response.eject(this.interceptorId);
  }
  public async RequestNewRefreshToken() {
    try {
      const res = await axios.post<RefreshToken>(
        '/sessions/refresh_tokens',
        undefined,
        {
          baseURL: config.REACT_APP_API,
          withCredentials: true,
        }
      );
      this.setToken(res.data.accessToken);
      return res.data;
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        history.push('/', { timeOut: true });
        throw err;
      }
    }
  }

  private RefreshInterceptor = async (err: AxiosError) => {
    const originalConfig: AxiosErrInterceptor = {
      ...err.config,
      _retry: false,
    };
    if (err.response?.status === 401 && !originalConfig._retry) {
      const res = await this.RequestNewRefreshToken();
      originalConfig._retry = true;
      originalConfig.headers.Authorization = `Bearer ${res?.accessToken}`;
      return this.instance(originalConfig);
    }
    return Promise.reject(err);
  };

  public Login = async (credentials: {
    username: string;
    password: string;
  }) => {
    try {
      this.EjectInterceptor();
      const res = await this.instance.post<Login>('/sessions/login', {
        ...credentials,
        userType: 'Logistics',
      });
      if (res.data.alerts) return res;
      this.setToken(res.data.accessToken);
      this.AddRefreshInterceptor();
      localStorage.setItem('isLoggedIn', 'true');
      return res;
    } catch (err) {
      throw err;
    }
  };
  public getAllIncoming = async (filter: FiltersAndPagination) => {
    const res = await this.instance.get<Pagination<PurchaseOrderRow[]>>(
      '/logistics/purchase_orders',
      {
        params: {
          page: filter.page,
          limit: filter.limit,
          ...this.createQueryObj(filter),
        },
      }
    );
    return res;
  };
  public getOneIncoming = async (_id: string) => {
    const res = await this.instance.get<IncomingOrderInterface>(
      `logistics/purchase_orders/${_id}`
    );
    return res;
  };

  public getAllOutGoing = async (filter: FiltersAndPagination) => {
    const res = await this.instance.get<Pagination<SalesOrderRow[]>>(
      '/logistics/sales_orders',
      {
        params: {
          page: filter.page,
          limit: filter.limit,
          ...this.createQueryObj(filter),
        },
      }
    );
    return res;
  };

  public getOneOutGoing = async (id: string) => {
    const res = await this.instance.get<OutgoingOrderInterface>(
      `/logistics/sales_orders/${id}`
    );
    return res;
  };

  public patchIncoming = async (
    status: string,
    id: string,
    invoiceNumber: string
  ) => {
    const res = await this.instance.patch(`/logistics/purchase_orders/${id}`, {
      status: status,
      deliveryTicketNumber: invoiceNumber,
    });
    return res;
  };

  public patchOutGoing = async (
    status: string,
    id: string,
    invoiceNumber: string
  ) => {
    const res = await this.instance.patch(`/logistics/sales_orders/${id}`, {
      status: status,
      deliveryTicketNumber: invoiceNumber,
    });
    return res;
  };

  public Logout = async () => {
    await this.instance.post('/sessions/logout');
    localStorage.clear();
  };

  public getAllStock = async (filter: FiltersAndPagination) => {
    const res = await this.instance.get<Pagination<StockProduct[]>>(
      '/logistics/products',
      {
        params: {
          page: filter.page,
          limit: filter.limit,
          ...this.createQueryObj(filter),
        },
      }
    );
    return res;
  };
}

export const APIFetcher = new Axios();
