import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIFetcher, StockProduct } from '../utils/axios';
import HeartSpinnerGlobal from './globalSpinnner';

interface StockTableProps {
  filters: { limit: number; page: number };
  setPages: React.Dispatch<React.SetStateAction<any>>;
}

export const StockTable: React.FC<StockTableProps> = ({
  filters,
  setPages,
}) => {
  const [products, setProducts] = useState<StockProduct[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    async function getOrders() {
      try {
        console.log(filters.page);
        setLoading(true);
        const res = await APIFetcher.getAllStock(filters);
        setPages(Math.ceil(res.data.totalCount / filters.limit));
        setProducts(res.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    getOrders();
  }, [filters.page]);

  if (loading) return <HeartSpinnerGlobal />;

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th scope="col">
            <h4>{t('stock:productName')}</h4>
          </th>
          <th scope="col">
            <h4>{t('stock:variantName')}</h4>
          </th>
          <th scope="col">
            <h4>{t('stock:productQuantity')}</h4>
          </th>
        </tr>
      </thead>
      <tbody>
        {products?.map((el) => {
          return (
            <tr className="orderRow">
              <td>
                <h5 className="m-0">
                  <strong>
                    {i18n.language === 'ar-EG'
                      ? el.product_arabicName
                      : el.product_name}
                  </strong>
                </h5>
              </td>
              <td>
                <h5 className="m-0">
                  <strong>
                    {i18n.language === 'ar-EG'
                      ? el.variant_arabicName
                      : el.variant_name}
                  </strong>
                </h5>
              </td>
              <td>
                <h5 className="m-0">{el.quantity}</h5>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
