import React from 'react';
import { useHistory } from 'react-router';
import { SalesOrderRow } from '../../utils/axios';

export const OneSalesOrderRow: React.FC<SalesOrderRow> = (props) => {
  const history = useHistory();
  return (
    <tr
      className="orderRow"
      onClick={() => {
        history.push(`/outgoing/${props._id}`, { from: '/' });
      }}
    >
      <td>
        <h5 className="m-0">
          <strong>{props._id}</strong>
          <br />
        </h5>
      </td>
      <td>
        <h5 className="m-0">
          <strong>{props.buyerId}</strong>
          <br />
        </h5>
      </td>

      <td>
        <strong>{new Date(props.createdAt).toLocaleDateString()}</strong>
      </td>
      <td>
        <div
          className={`OrderStatus ${
            props.numOfPurchaseOrders === props.numOfdeliveredPurchaseOrders
              ? 'Green'
              : 'Red'
          }`}
        >
          <h6 className="mb-0">
            {props.numOfdeliveredPurchaseOrders}/{props.numOfPurchaseOrders}
          </h6>
        </div>
      </td>
      <td>
        <div
          className={`OrderStatus ${
            props.status === 'PROCESSING' ? 'Blue' : 'Green'
          }`}
        >
          <h6 className="mb-0">{props.status}</h6>
        </div>
      </td>
    </tr>
  );
};
