import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { StockTable } from '../componenets/stockTable';

interface StockProductsProps {}

export const StockProducts: React.FC<StockProductsProps> = () => {
  const history = useHistory();
  const [pages, setPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pagesElements = [];
  for (let i = 0; i < pages; i++) {
    pagesElements.push(
      <li className="page-item">
        <button
          key={i + 1}
          className="page-link"
          onClick={() => {
            setCurrentPage(i + 1);
          }}
        >
          {i + 1}
        </button>
      </li>
    );
  }

  const { t } = useTranslation();

  return (
    <div className="container" style={{ marginTop: '1rem' }}>
      <div className="d-flex justify-content-between mb-3">
        <h2 className="mb-0" style={{ color: '#302363' }}>
          <i className="fas fa-boxes mx-1"></i>
          {t('stock:stock')}
        </h2>
        <button
          className="btn btn-dark mx-1"
          type="button"
          onClick={() => {
            history.push('/');
          }}
        >
          <i className="fas fa-boxes mx-1"></i>
          {t('stock:backToOrders')}
        </button>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <StockTable
              setPages={setPages}
              filters={{
                limit: 20,
                page: currentPage,
              }}
            />
          </div>
        </div>
        <div className="card-footer d-flex justify-content-center">
          <nav>
            <ul className="pagination">
              <li className="page-item">
                <button
                  className="page-link"
                  aria-label="Previous"
                  onClick={() => {
                    if (currentPage - 1 < 1) return;
                    setCurrentPage(currentPage - 1);
                  }}
                >
                  <span aria-hidden="true">«</span>
                </button>
              </li>
              {pagesElements}

              <li className="page-item">
                <button
                  className="page-link"
                  aria-label="Next"
                  onClick={() => {
                    if (currentPage + 1 > pages) return;
                    setCurrentPage(currentPage + 1);
                  }}
                >
                  <span aria-hidden="true">»</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};
