import React from 'react';
function HeartSpinnerGlobal() {
  return (
    <div className="vw-100 vh-100 d-flex justify-content-center align-items-center">
      <div className="heart-rate-global">
        <svg height="73" width="150" xmlns="http://www.w3.org/2000/svg">
          <g>
            <title>background</title>
            <rect
              fill="none"
              height="602"
              id="canvas_background"
              width="802"
              x="-1"
              y="-1"
            ></rect>
          </g>
          <g>
            <title>Layer 1</title>
            <polyline
              fill="none"
              id="svg_1"
              points="0,45.486 38.514,45.486 44.595,33.324 50.676,45.486 57.771,45.486 62.838,55.622 71.959,9 80.067,63.729 84.122,45.486 97.297,45.486 103.379,40.419 110.473,45.486 150,45.486"
              stroke="#8065a0"
              strokeMiterlimit="10"
              strokeWidth="5"
            ></polyline>
          </g>
        </svg>
        <div className="fade-in-global"></div>
        <div className="fade-out-global"></div>
        <div className="heart-circle-global">
          <svg viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg">
            {/*<path className="circle-heart-stroke-global" fill="none" d="M80,5 a75,75 0 0,1 75,75" />*/}
            <circle
              className="circle-heart-stroke-global"
              cx="80"
              cy="80"
              fillOpacity="0"
              r="75"
            ></circle>
          </svg>
        </div>
      </div>
    </div>
  );
}

export default HeartSpinnerGlobal;
