import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import HeartSpinnerGlobal from '../componenets/globalSpinnner';
import { APIFetcher } from './axios';
interface ContextType {
  AuthState: boolean;
  Login: () => void;
  Logout: VoidFunction;
}

const AuthContext = React.createContext<ContextType>({
  Login: () => {},
  AuthState: false,
  Logout: () => {},
});

export const AuthComponent: React.FC = ({ children }) => {
  const [AuthState, setAuthState] = useState<boolean>(false);
  const [Loading, setLoading] = useState(true);
  const Location = useLocation<{ timeOut: boolean }>();
  useEffect(() => {
    //Initialize state at first render
    async function getInitialToken() {
      try {
        const res = await APIFetcher.RequestNewRefreshToken();
        if (res?.userType === 'Logistics') {
          setAuthState(true);
        } else setAuthState(false);
        setLoading(false);
      } catch {
        setLoading(false);
        setAuthState(false);
      }
    }
    getInitialToken();
  }, []);
  useEffect(() => {
    if (Location.state?.timeOut) {
      setAuthState(false);
    }
  }, [Location.state]);
  function Login() {
    setAuthState(true);
  }
  function Logout() {
    setAuthState(false);
  }
  return (
    <AuthContext.Provider value={{ Login, Logout, AuthState }}>
      {Loading ? <HeartSpinnerGlobal /> : children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthComponent as default, useAuth };
