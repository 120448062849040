import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import {
  APIFetcher,
  IncomingOrderInterface,
  OutgoingOrderInterface,
} from '../utils/axios';
//import { CreateTotal } from '../utils/helperFunctions';
import ReactModal from '../utils/reactModalProtal';
import { Modal } from 'bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { CreateTotal } from '../utils/helperFunctions';
interface MatchParams {
  id: string;
}

interface IncomingOrderProps extends RouteComponentProps<MatchParams> {}
export const IncomingOrder: React.FC<IncomingOrderProps> = ({ match }) => {
  const [order, setOrder] = useState<IncomingOrderInterface>();
  const [loading, setLoading] = useState<boolean>(true);
  const [invoiceNumber, setInvoiceNumber] = useState<string>();
  const [fieldError, setFieldError] = useState<boolean>();
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalState, setModalState] = useState<Modal>();
  const location = useLocation<{ from?: string }>();
  const history = useHistory();
  useEffect(() => {
    async function getIncoming() {
      try {
        const res = await APIFetcher.getOneIncoming(match.params.id);
        setOrder(CreateTotal(res.data));
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
    getIncoming();
  }, []);
  function handleIncomingUpdate() {
    if (modalRef.current !== null) {
      const ModalObj = new Modal(modalRef.current);
      setModalState(ModalObj);
      ModalObj.show();
    }
  }
  async function submitDelivery() {
    if (invoiceNumber === undefined) return setFieldError(true);
    try {
      const res = await APIFetcher.patchIncoming(
        'DELIVERED',
        match.params.id,
        invoiceNumber
      );
      modalState?.hide();

      history.push('/');
    } catch {
      //TODO: Handle Errors
    }
  }
  const { t, i18n } = useTranslation();
  if (order === undefined && loading) return <h1>Loading</h1>;
  if (typeof order === 'undefined') return <h1>Error</h1>;
  const orderID = order._id;
  return (
    <>
      <ReactModal>
        <div className="modal fade" role="dialog" tabIndex={-1} ref={modalRef}>
          <div
            className="modal-dialog modal-dialog-centered modal-fullscreen-md-down"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  <i className="fas fa-box-open m-1"></i>
                  {t('singleIncoming:modal.header')}
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p className="m-0">
                  <Trans i18nKey="singleIncoming:modal.msg">
                    You are about to mark order with
                    <strong> ID: {{ orderID }}</strong>as
                    <strong>Delivered</strong>
                  </Trans>
                </p>
                <p>{t('singleIncoming:modal.msg1')} </p>
                <form>
                  <label className="form-label">
                    <strong>{t('singleIncoming:modal.fieldLabel')}</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) => setInvoiceNumber(e.target.value)}
                  />
                  {fieldError && (
                    <div className="form-error-generic">
                      {t('singleIncoming:modal.error')}
                    </div>
                  )}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-light"
                  type="button"
                  data-bs-dismiss="modal"
                >
                  {t('singleIncoming:modal.clos')}
                </button>
                <button
                  className="btn btn-success"
                  type="button"
                  onClick={submitDelivery}
                >
                  {t('singleIncoming:modal.complete')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
      <div className="container" style={{ marginTop: '1rem' }}>
        <div className="d-flex justify-content-between">
          <h2 className="mb-0" style={{ color: '#302363' }}>
            <i className="fas fa-box mx-1"></i>
            {t('singleIncoming:header')}
            <br />
          </h2>
          <button
            className="btn btn-secondary ms-2"
            type="button"
            onClick={() => {
              if (location.state?.from === '/') history.goBack();
              else history.push('/');
            }}
          >
            <i className="far fa-caret-square-left mx-1"></i>
            {t('common:backToOrders')}
          </button>
        </div>

        <hr />
        <div className="card">
          <div className="card-header">
            <h3>
              {t('singleIncoming:shipmentID')}:&nbsp;
              <strong>{order._id}</strong>
              <br />
            </h3>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-10 d-flex align-items-center">
                <div>
                  <h5>
                    <strong>
                      {t('singleIncoming:orderDate')}:{' '}
                      {new Date(order.createdAt).toLocaleDateString()}
                    </strong>
                  </h5>
                  <h5>
                    <strong>
                      {t('singleIncoming:deliveredAt')}:{' '}
                      {order.deliveredAt
                        ? new Date(order.deliveredAt).toLocaleDateString()
                        : '-'}{' '}
                    </strong>
                  </h5>
                </div>
              </div>
              <div className="col-2 d-flex flex-column">
                <div
                  className={`OrderStatus ${
                    order.status === 'PROCESSING' ? 'Blue' : 'Green'
                  } mb-2`}
                >
                  <h6 className="mb-0">{order.status}</h6>
                </div>
                <button
                  className="btn btn-success"
                  type="button"
                  onClick={handleIncomingUpdate}
                >
                  {t('singleIncoming:markAs')}
                </button>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <div>
                  <h2>{t('singleIncoming:table.header')}</h2>
                  <h5 className="m-0">
                    {t('singleIncoming:table.noProducts')}: {order.items.length}
                  </h5>
                  <h5>
                    {t('singleIncoming:table.total')}: {order.total}
                  </h5>
                  <div style={{ overflowY: 'auto', height: '37rem' }}>
                    {order.items.map((el) => {
                      return (
                        <div className="card m-2" key={el.productId}>
                          <div className="card-body shadow">
                            <div className="d-flex">
                              <div>
                                <h3>
                                  {i18n.language === 'ar-EG'
                                    ? el.product.arabicName
                                    : el.product.name}
                                  <br />
                                </h3>
                                <h5 className="text-muted mb-2">
                                  {el.productId}
                                  <br />
                                </h5>
                              </div>
                            </div>
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      {t('singleIncoming:table.variantName')}
                                    </th>
                                    <th className="text-end">
                                      {t('singleIncoming:table.qty')}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {el.variants.map((elVar) => {
                                    return (
                                      <tr id={elVar.variantId}>
                                        <td>
                                          {i18n.language === 'ar-EG'
                                            ? elVar.productVariant.arabicName
                                            : elVar.productVariant.name}
                                        </td>
                                        <td className="text-end">
                                          {elVar.quantity}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td className="text-end">
                                      <strong>
                                        {t('singleIncoming:table.totalProd')}
                                      </strong>
                                    </td>
                                    <td className="text-end">{el.totalQty}</td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-3 border-start">
                <div>
                  <h2>{t('singleIncoming:vendor.header')}</h2>
                  <h5 className="m-0">
                    <strong>{t('singleIncoming:vendor.vendorID')}</strong>
                  </h5>
                  <h5>{order.vendor._id}</h5>
                  <h5 className="m-0">
                    <strong>{t('singleIncoming:vendor.name')}</strong>
                  </h5>
                  <h5>{order.vendor.name}</h5>
                  <h5 className="m-0">
                    <strong>{t('singleIncoming:vendor.contactPerson')}</strong>
                  </h5>
                  <h5>
                    {order.vendor.contactPersonFirstName}{' '}
                    {order.vendor.contactPersonLastName}
                  </h5>
                  <h5 className="m-0">
                    <strong>{t('singleIncoming:vendor.address')}</strong>
                  </h5>
                  <h5>{order.vendor.address}</h5>
                  <h5 className="m-0">
                    <strong>{t('singleIncoming:vendor.phone')}</strong>
                  </h5>
                  <h5>{order.vendor.phone}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer"></div>
        </div>
      </div>
    </>
  );
};
