import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  APIFetcher,
  FiltersAndPagination,
  PurchaseOrderRow,
  SalesOrderRow,
} from '../utils/axios';
import { OnePurchaseOrderRow } from './orders/onePurchaseOrderRow';

interface IncomingTableProps {
  filters: FiltersAndPagination;
  setPages: React.Dispatch<React.SetStateAction<any>>;
}

export const IncomingTable: React.FC<IncomingTableProps> = ({
  setPages,
  filters,
}) => {
  const [orders, setOrders] = useState<PurchaseOrderRow[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    //TODO: Add cleanup function to cancel request
    async function getSalesOrder() {
      const res = await APIFetcher.getAllIncoming(filters);
      setPages(Math.ceil(res.data.totalCount / filters.limit));
      setOrders(res.data.data);
    }
    getSalesOrder();
  }, [filters.page]);
  return (
    <table className="table table-borderless">
      <thead>
        <tr>
          <th>{t('incoming:shipmentID')}</th>
          <th>{t('incoming:orderID')}</th>
          <th>{t('incoming:vendorID')}</th>
          <th>{t('incoming:orderDate')}</th>
          <th>{t('incoming:status')}</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((el) => {
          return <OnePurchaseOrderRow {...el} key={el._id} />;
        })}
      </tbody>
    </table>
  );
};
