import React, { useState } from 'react';
import { APIFetcher } from '../utils/axios';
import axios from 'axios';
import { LoginError } from '../componenets/login/error';
import { useAuth } from '../utils/authContext';
import { useTranslation } from 'react-i18next';
interface LoginProps {}
interface LoginErrorInt {
  message: string;
  type: string;
  path: string;
}
export const Login: React.FC<LoginProps> = () => {
  const [loginDetails, setLoginDetails] = useState({
    username: '',
    password: '',
  });
  const { Login } = useAuth();
  const { t, i18n } = useTranslation();
  const [errorBackend, setErrorBackend] = useState<LoginErrorInt[]>([]);
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setLoginDetails((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  }
  async function handleSubmit() {
    try {
      await APIFetcher.Login(loginDetails);
      Login();
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        setErrorBackend(err.response?.data.errors);
      }
    }
  }
  return (
    <React.Fragment>
      <div className="shadow login-box">
        <div className="row m-0">
          <div className="col d-flex justify-content-center align-items-center mt-2">
            <img
              className="d-flex img-login-box"
              src="assets/img/ultra%20teb%20final.svg"
              alt="login-logo"
            />
            <div>
              <h1 className="login-title">
                ULTRA<strong>TEB</strong>
              </h1>
              <h1 className="mt-0 mb-0 sub-login">
                <em>Logistics</em>
              </h1>
            </div>
          </div>
        </div>
        {errorBackend?.length > 0 &&
          errorBackend.map((el, i) => {
            return <LoginError message={el.message} key={i} />;
          })}
        <div className="row m-0">
          <div className="col">
            <div className="col d-inline-flex justify-content-center align-items-center">
              <i className="fas fa-user-lock"></i>
              <h3 className="login-box-title m-0">
                <strong>{t('login:header')}</strong>
              </h3>
            </div>
          </div>
        </div>
        <div className="row m-0">
          <div className="col-12">
            <form>
              <div className="form-group">
                <input
                  className="form-control login-field mb-3"
                  type="text"
                  placeholder={t('login:username')}
                  value={loginDetails.username}
                  name="username"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control login-field mb-3"
                  type="password"
                  placeholder={t('login:password')}
                  name="password"
                  value={loginDetails.password}
                  onChange={handleChange}
                />
              </div>
            </form>
          </div>
          <div className="col-12 d-flex flex-column justify-content-center align-content-center mb-2">
            <button
              className="btn btn-primary flex-grow-1 login-btn"
              type="button"
              onClick={handleSubmit}
            >
              <strong>{t('login:button')}</strong>
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="d-flex login-wrapper">
          <img
            className="logo-img-login"
            src="assets/img/ultra%20teb%20final.svg"
            alt="logo-login-background"
          />
        </div>
      </div>
    </React.Fragment>
  );
};
