import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import { APIFetcher, OutgoingOrderInterface } from '../utils/axios';
//import { createPurchaseOrderTotal } from '../utils/helperFunctions';
import ReactModal from '../utils/reactModalProtal';
import { Modal } from 'bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { createSalesOrderTotal } from '../utils/helperFunctions';
interface MatchParams {
  id: string;
}
interface OutgoingOrderProps extends RouteComponentProps<MatchParams> {}

export const OutgoingOrder: React.FC<OutgoingOrderProps> = ({ match }) => {
  const [order, setOrder] = useState<OutgoingOrderInterface>();
  const [loading, setLoading] = useState<boolean>(true);
  const [invoiceNumber, setInvoiceNumber] = useState<string>();
  const [fieldError, setFieldError] = useState<boolean>();
  const modalRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const location = useLocation<{ from?: string }>();
  const [modalState, setModalState] = useState<Modal>();
  useEffect(() => {
    async function getOrder() {
      try {
        const res = await APIFetcher.getOneOutGoing(match.params.id);
        setOrder(createSalesOrderTotal(res.data));
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
    getOrder();
  }, []);

  function handleOutgoingUpdate() {
    if (modalRef.current !== null) {
      const ModalObj = new Modal(modalRef.current);
      setModalState(ModalObj);
      ModalObj.show();
    }
  }
  async function submitDelivery() {
    if (invoiceNumber === undefined) return setFieldError(true);
    try {
      const res = await APIFetcher.patchOutGoing(
        'DELIVERED',
        match.params.id,
        invoiceNumber
      );
      modalState?.hide();
      history.push('/');
    } catch {
      //TODO: ADD ERROR HANDLING
    }
  }
  const { t } = useTranslation();
  if (order === undefined && loading) return <h1>Loading</h1>;
  if (order === undefined) return <h1>Error</h1>;
  const orderID = order._id;
  return (
    <>
      <ReactModal>
        <div className="modal fade" role="dialog" tabIndex={-1} ref={modalRef}>
          <div
            className="modal-dialog modal-dialog-centered modal-fullscreen-md-down"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  <i className="fas fa-box-open m-1"></i>
                  {t('singleIncoming:modal.header')}
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p className="m-0">
                  <Trans i18nKey="singleIncoming:modal.msg">
                    You are about to mark order with
                    <strong> ID: {{ orderID }}</strong>as
                    <strong>Delivered</strong>
                  </Trans>
                </p>
                <p>{t('singleIncoming:modal.msg1')} </p>
                <form>
                  <label className="form-label">
                    <strong>{t('singleIncoming:modal.fieldLabel')}</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) => setInvoiceNumber(e.target.value)}
                  />
                  {fieldError && (
                    <div className="form-error-generic">
                      {t('singleIncoming:modal.error')}
                    </div>
                  )}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-light"
                  type="button"
                  data-bs-dismiss="modal"
                >
                  {t('singleIncoming:modal.clos')}
                </button>
                <button
                  className="btn btn-success"
                  type="button"
                  onClick={submitDelivery}
                >
                  {t('singleIncoming:modal.complete')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
      <div className="container" style={{ marginTop: '1rem' }}>
        <div className="d-flex justify-content-between">
          <h2 className="mb-0" style={{ color: '#302363' }}>
            <i className="fas fa-box mx-1"></i>
            {t('singleOutgoing:header')}
            <br />
          </h2>
          <button
            className="btn btn-secondary ms-2"
            type="button"
            onClick={() => {
              if (location.state?.from === '/') history.goBack();
              else history.push('/');
            }}
          >
            <i className="far fa-caret-square-left mx-1"></i>
            {t('common:backToOrders')}
          </button>
        </div>

        <hr />
        <div className="card">
          <div className="card-header">
            <h3>
              {t('singleOutgoing:orderId')}:&nbsp;<strong>{order._id}</strong>
              <br />
            </h3>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-10 d-flex align-items-center">
                <div>
                  <h5>
                    <strong>
                      {t('singleOutgoing:orderDate')}:{' '}
                      {new Date(order.createdAt).toLocaleDateString()}
                    </strong>
                  </h5>
                  <h5>
                    <strong>
                      {t('singleOutgoing:deliveredAt')}:{' '}
                      {order.deliveredAt
                        ? new Date(order.deliveredAt).toLocaleDateString()
                        : '-'}
                    </strong>
                  </h5>
                </div>
              </div>
              <div className="col-2 d-flex flex-column">
                <div
                  className={`OrderStatus ${
                    order.status === 'PROCESSING' ? 'Blue' : 'Green'
                  } mb-2`}
                >
                  <h6 className="mb-0">{order.status}</h6>
                </div>
                <button
                  className="btn btn-success"
                  type="button"
                  onClick={handleOutgoingUpdate}
                >
                  {t('singleOutgoing:markAs')}
                </button>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <div>
                  <h2>{t('singleOutgoing:table.header')}</h2>
                  <h5 className="m-0">
                    {t('singleOutgoing:table.noShipments')}:{' '}
                    {order.purchaseOrders.length}
                  </h5>
                  <h5 className="m-0">
                    {t('singleOutgoing:table.totalProd')}: {order.numOfProducts}
                  </h5>
                  <h5>
                    {t('singleOutgoing:table.totalQty')}: {order.total}
                  </h5>
                </div>
                <hr />
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr className="CartTR">
                        <th>
                          {t(
                            'singleOutgoing:table.vendorTable.shipment.header'
                          )}
                        </th>
                        <th className="text-start">
                          {t(
                            'singleOutgoing:table.vendorTable.products.header'
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.purchaseOrders.map((el) => {
                        return (
                          <tr className="CartTableRow">
                            <td className="CartTableCell">
                              <div className="CartSubCellData"></div>
                              <div>
                                <h6 className="m-0">
                                  <strong>
                                    {t(
                                      'singleOutgoing:table.vendorTable.shipment.id'
                                    )}
                                  </strong>
                                </h6>
                                <h6>
                                  <strong>{el._id}</strong>
                                  <br />
                                </h6>
                                <h6 className="m-0">
                                  <strong>
                                    {t(
                                      'singleOutgoing:table.vendorTable.shipment.noProd'
                                    )}
                                    &nbsp;
                                  </strong>
                                  {el.items.length}
                                </h6>
                                <h6>
                                  <strong>
                                    {t(
                                      'singleOutgoing:table.vendorTable.products.totalQty'
                                    )}
                                    &nbsp;
                                  </strong>
                                  {el.total}
                                </h6>
                                <h6 className="m-0">
                                  <strong>
                                    {t(
                                      'singleOutgoing:table.vendorTable.shipment.vendorID'
                                    )}
                                  </strong>
                                </h6>
                                <h6>{el.vendor._id}</h6>
                                <h6 className="m-0">
                                  <strong>
                                    {t(
                                      'singleOutgoing:table.vendorTable.shipment.name'
                                    )}
                                  </strong>
                                </h6>
                                <h6>{el.vendor.name}</h6>
                                <h6 className="m-0">
                                  <strong>
                                    {t(
                                      'singleOutgoing:table.vendorTable.shipment.phone'
                                    )}
                                  </strong>
                                </h6>
                                <h6>{el.vendor.phone}</h6>
                                <h6>
                                  <strong>
                                    {t(
                                      'singleOutgoing:table.vendorTable.shipment.status'
                                    )}
                                  </strong>
                                </h6>
                                <div
                                  className={`OrderStatus ${
                                    el.status === 'PROCESSING'
                                      ? 'Blue'
                                      : 'Green'
                                  } mb-2`}
                                >
                                  <h6 className="mb-0">{el.status}</h6>
                                </div>
                              </div>
                            </td>
                            <td className="CartTableCell border-start">
                              {el.items.map((elProd) => {
                                return (
                                  <div key={elProd.productId}>
                                    <h5>
                                      <strong>{elProd.product.name}</strong>
                                      <br />
                                    </h5>
                                    <p className="m-0">
                                      <strong>ID: {elProd.productId}</strong>
                                    </p>
                                    <div className="table-responsive">
                                      <table className="table table-sm">
                                        <thead>
                                          <tr>
                                            <th>
                                              {t(
                                                'singleOutgoing:table.vendorTable.products.variant'
                                              )}
                                            </th>
                                            <th className="text-end">
                                              {t(
                                                'singleOutgoing:table.vendorTable.products.quantity'
                                              )}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {elProd.variants.map((elVar) => {
                                            return (
                                              <tr key={el._id}>
                                                <td>
                                                  {elVar.productVariant.name}
                                                </td>
                                                <td className="text-end">
                                                  {elVar.quantity}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                        <tfoot>
                                          <tr>
                                            <td>
                                              <h5 className="text-end m-0">
                                                {t(
                                                  'singleOutgoing:table.vendorTable.products.totalQty'
                                                )}
                                              </h5>
                                            </td>
                                            <td className="text-end">
                                              <h4 className="m-0">
                                                {elProd.totalQty}
                                              </h4>
                                            </td>
                                          </tr>
                                        </tfoot>
                                      </table>
                                    </div>
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-3 border-start">
                <div>
                  <h2>{t('singleOutgoing:buyer.header')}</h2>
                  <h5 className="m-0">
                    <strong>{t('singleOutgoing:buyer.buyerID')}</strong>
                  </h5>
                  <h5>{order.buyer._id}</h5>
                  <h5 className="m-0">
                    <strong>{t('singleOutgoing:buyer.name')}</strong>
                  </h5>
                  <h5>{order.buyer.name}</h5>
                  <h5 className="m-0">
                    <strong>{t('singleOutgoing:buyer.contactPerson')}</strong>
                  </h5>
                  <h5>
                    {order.buyer.contactPersonFirstName}{' '}
                    {order.buyer.contactPersonLastName}
                  </h5>
                  <h5 className="m-0">
                    <strong>{t('singleOutgoing:buyer.address')}</strong>
                  </h5>
                  <h5 className="m-0">{order.buyer.addressLine}</h5>
                  <h5 className="m-0">{order.buyer.district}</h5>
                  <h5>{order.buyer.governorate}</h5>
                  <h5 className="m-0">
                    <strong>{t('singleOutgoing:buyer.phone')}</strong>
                  </h5>
                  <h5>{order.buyer.phone}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer"></div>
        </div>
      </div>
    </>
  );
};
