import { IncomingOrderInterface, OutgoingOrderInterface } from './axios';
export function CreateTotal(
  purchaseOrder: IncomingOrderInterface
): IncomingOrderInterface {
  let totalQty = 0;
  purchaseOrder.items.forEach((elProduct) => {
    let totalVariantQty = 0;
    elProduct.variants.forEach((elVar) => {
      totalVariantQty += elVar.quantity;
    });
    elProduct.totalQty = totalVariantQty;
    totalQty += totalVariantQty;
  });
  purchaseOrder.total = totalQty;
  return purchaseOrder;
}
export function createSalesOrderTotal(
  saleOrder: OutgoingOrderInterface
): OutgoingOrderInterface {
  let totalQuantity = 0;
  let totalNumber = 0;
  saleOrder.purchaseOrders.forEach((el) => {
    console.log(el);
    el = CreateTotal(el);
    totalQuantity += el.total ? el.total : 0;
    totalNumber += el.items.length;
  });
  saleOrder.numOfProducts = totalNumber;
  saleOrder.total = totalQuantity;
  return saleOrder;
}
