import React from 'react';

interface errorProps {
  message: string;
}

export const LoginError: React.FC<errorProps> = ({ message }) => {
  return (
    <div className="row m-0">
      <div className="col">
        <div className="d-flex align-items-stretch align-content-center error-login-box">
          <div className="d-flex align-items-center error-box-icon">
            <i className="fas fa-exclamation-circle m-2"></i>
          </div>
          <div className="d-flex flex-fill justify-content-center align-content-center">
            <p className="mb-0 mx-1">
              <strong>Error: </strong>
              {message}
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
