// import { useState } from 'react';
import { Login } from './pages/login';
import { Main } from './pages/main';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { IncomingOrder } from './pages/incomingOrder';
import { Header } from './componenets/header';
import { OutgoingOrder } from './pages/outgoingOrder';
import { FulfilledOrders } from './pages/fulfilledOrders';
import { useAuth } from './utils/authContext';
import { StockProducts } from './pages/stockProducts';
import i18n from 'i18next';

function App() {
  const { AuthState } = useAuth();
  i18n.on('languageChanged', (lng) => {
    document.documentElement.setAttribute('lang', lng);
  });
  i18n.on('initialized', (opt) => {
    document.documentElement.setAttribute('lang', opt.lng ? opt.lng : 'ar-EG');
  });

  if (AuthState) {
    return (
      <>
        <Header />
        <Switch>
          <Route component={Main} path="/" exact />
          <Route path="/incoming/:id" component={IncomingOrder} />
          <Route path="/outgoing/:id" component={OutgoingOrder} />
          <Route path="/fulfilledOrders" component={FulfilledOrders} />
          <Route path="/stock" component={StockProducts} />
        </Switch>
      </>
    );
  }
  return <Login />;
}

export default App;
