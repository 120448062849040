import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAuth } from '../utils/authContext';
import { APIFetcher } from '../utils/axios';

interface headerProps {}
export const Header: React.FC<headerProps> = ({}) => {
  const { Logout } = useAuth();
  const history = useHistory();
  async function submitLogout() {
    await APIFetcher.Logout();
    Logout();
    history.push('/');
  }

  async function changeLanguage() {
    if (i18n.language === 'en-US') await i18n.changeLanguage('ar-EG');
    else await i18n.changeLanguage('en-US');
  }

  const { t, i18n } = useTranslation();
  return (
    <div
      className="d-flex justify-content-between"
      style={{ backgroundColor: '#302363', padding: '0.8rem 1rem' }}
    >
      <div className="d-flex align-items-center">
        <img
          className="m-2"
          src={`${process.env.REACT_APP_URL}/assets/img/ultra%20teb%20final%20white.svg`}
          style={{ height: '3rem' }}
        />
        <h1
          className="m-0 m-1"
          style={{ color: '#fff', fontFamily: 'Alata, sans-serif' }}
        >
          <strong>UltraTeb</strong>
        </h1>
        <h1 className="mb-2" style={{ color: 'white' }}>
          |
        </h1>
        <h2
          className="mx-1 mb-0 mt-1"
          style={{ color: '#fff', fontFamily: 'Alata, sans-serif' }}
        >
          <em>Logistics</em>
        </h2>
      </div>
      <div>
        <button
          className="btn btn-outline-light ms-2 me-2"
          type="button"
          onClick={submitLogout}
        >
          <i className="fas fa-sign-out-alt m-1"></i>
          {t('common:logout')}
        </button>
        <button
          className="btn btn-outline-light ms-2 me-2"
          type="button"
          onClick={changeLanguage}
        >
          <i className="fas fa-globe m-1"></i>
          {i18n.language === 'en-US' ? 'العربية' : 'English'}
        </button>
      </div>
    </div>
  );
};
