import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import { IncomingTable } from '../componenets/incomingTable';
import { OutGoingTable } from '../componenets/outGoingTable';

interface MainProps {}

export const Main: React.FC<MainProps> = ({}) => {
  const [currentTab, setCurrenTab] = useState('Incoming');
  const history = useHistory();
  const location = useLocation();
  const [pages, setPages] = useState<number>(1);
  const params = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState<number>(
    params.has('page') ? Number(params.get('page')) : 1
  );
  const pagesElements = [];

  useEffect(() => {
    if (params.has('page')) setCurrentPage(Number(params.get('page')));
  }, [params]);

  for (let i = 0; i < pages; i++) {
    pagesElements.push(
      <li className="page-item" key={i + 1}>
        <a
          className="page-link"
          href="#"
          onClick={() => {
            history.push({
              pathname: '/',
              search: `?page=${i + 1}`,
            });
          }}
        >
          {i + 1}
        </a>
      </li>
    );
  }
  const { t } = useTranslation();

  return (
    <>
      <div className="container" style={{ marginTop: '1rem' }}>
        <div className="d-flex justify-content-between mb-3">
          <h2 className="mb-0" style={{ color: '#302363' }}>
            <i className="fas fa-boxes mx-1"></i>
            {t('orders:orders')}
          </h2>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-dark active-toggle mx-1"
              type="button"
              onClick={() => {
                history.push('/fulfilledOrders');
              }}
            >
              <i className="fas fa-boxes mx-1"></i>
              {t('orders:fulfilledOrders')}
            </button>
            <button
              className="btn btn-outline-dark mx-1"
              type="button"
              onClick={() => {
                history.push('/stock');
              }}
            >
              <i className="fas fa-boxes mx-1"></i>
              {t('orders:stockProducts')}
            </button>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <div>
              <ul className="nav nav-pills" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    role="tab"
                    data-bs-toggle="pill"
                    href="#tab-1"
                    onClick={() => {
                      setCurrenTab('Incoming');
                      setCurrentPage(1);
                    }}
                  >
                    <i className="fa fa-arrow-circle-down mx-1"></i>
                    <strong>{t('orders:incomingShipments')}</strong>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    role="tab"
                    data-bs-toggle="pill"
                    href="#tab-2"
                    onClick={() => {
                      setCurrenTab('OutGoing');
                      setCurrentPage(1);
                    }}
                  >
                    <i className="fa fa-arrow-circle-up mx-1"></i>
                    <strong>{t('orders:outgoingOrders')}</strong>
                  </a>
                </li>
              </ul>
              <div className="tab-content"></div>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              {currentTab === 'Incoming' ? (
                <IncomingTable
                  setPages={setPages}
                  filters={{
                    page: currentPage,
                    limit: 20,
                    filters: [{ sort: '-createdAt' }],
                  }}
                />
              ) : (
                <OutGoingTable
                  setPages={setPages}
                  filters={{
                    page: currentPage,
                    limit: 20,
                    filters: [{ sort: '-createdAt' }],
                  }}
                />
              )}
            </div>
          </div>
          <div className="card-footer d-flex justify-content-center">
            <nav>
              <ul className="pagination">
                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    aria-label="Previous"
                    onClick={() => {
                      if (currentPage - 1 < 1) return;
                      history.push({
                        pathname: '/',
                        search: `?page=${currentPage - 1}`,
                      });
                    }}
                  >
                    <span aria-hidden="true">«</span>
                  </a>
                </li>
                {pagesElements}

                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    aria-label="Next"
                    onClick={() => {
                      if (currentPage + 1 > pages) return;
                      history.push({
                        pathname: '/',
                        search: `?page=${currentPage + 1}`,
                      });
                    }}
                  >
                    <span aria-hidden="true">»</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};
